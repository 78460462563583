import { useState } from 'react'

export default function ChatModule() {
  const [currentMsg, setCurrentMsg] = useState('')

  return (
    <div className="Chat-module">
      This is a chat module

      <div className="Input"
        autofocus={true}
        contentEditable={true}
        value={currentMsg}
        onChange={e => setCurrentMsg(e.target.value)}>
      </div>
    </div>
  )
}
