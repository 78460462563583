import { ChatModule } from './components';

import './styles/ChatModule.css'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <ChatModule />
      </header>
    </div>
  );
}

export default App;
